<template>
  <div class="root">
    <div class="title">应用市场</div>
    <div class="list" v-for="item in list" :key="item.name">
      <div class="name_wrap">
        <el-avatar :src="item.avatar"></el-avatar>
        <div class="name">{{ item.name }}</div>
      </div>
      <el-button type="primary" round @click="download(item)">下载</el-button>
    </div>
    <div class="bottom">
      <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备19021509号-5</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      list: [
        // {
        //   name: '微信',
        //   url: 'https://b2528788b3c06a86c07f921016971ea8.dlied1.cdntips.net/dldir1.qq.com/weixin/android/weixin8031android2280.apk',
        //   avatar: 'https://android-artworks.25pp.com/fs08/2022/09/21/5/106_7c1321d74e5c6c71e267abf464f6889a_con_130x130.png',
        // },
        {
          name: '企业微信',
          url: 'https://dldir1.qq.com/wework/work_weixin/WeCom_android_4.0.20.20355_100038.apk',
          avatar: 'https://android-artworks.25pp.com/fs08/2022/12/05/4/110_671d9ba09e87d916707cc768484bd3e7_con_130x130.png',
        },
        {
          name: '钉钉',
          url: 'https://video.xdfok.com/apk/dingdingv6.0.5.apk',
          avatar: 'https://android-artworks.25pp.com/fs08/2022/11/09/5/106_6c7d074d182ad87fa5ac73f1f87729d4_con_130x130.png',
        },
        {
          name: '腾讯会议',
          url: 'https://video.xdfok.com/apk/tengxunhuiyiv3.11.4.443.apk',
          avatar: 'https://android-artworks.25pp.com/fs08/2022/12/08/8/106_980ac1616abffddc74903692a4c144e3_con_130x130.png',
        },
        {
          name: '智慧中小学',
          url: 'https://video.xdfok.com/apk/ykt_4666308_reinforce.apk',
          avatar: 'https://pp.myapp.com/ma_icon/0/icon_54203788_1669884182/256',
        },
      ],
    }
  },
  methods: {
    download(item) {
      let url = item.url
      const a = document.createElement('a')
      a.href = url
      a.download = item.name + '.apk' // 下载后文件名
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click() // 点击下载
      document.body.removeChild(a) // 下载完成移除元素
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.root {
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 20px;
  }
}
.list {
  margin: 6px 10px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  .name_wrap {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    .name {
      padding: 10px;
    }
  }
}

.bottom {
  position: absolute;
  padding: 20px;
  bottom: 0;
}
</style>
